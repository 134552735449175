import React from 'react'
import { Link } from 'gatsby'

import { getFormattedDate } from '../utils/helpers'

// this class will be used in the index to construct the list of recent publications
// the getSimplifiedPosts function from helpers.js is used to get the input

export const Post = ({ node, prefix, newspaper }) => {
  let formattedDate

  if (node.date) {
    if (!newspaper) { // print large year on blog page, not used on index
      const dateArr = node.date.split(' ')
      dateArr.pop()

      dateArr[0] = dateArr[0].slice(0, 3)
      formattedDate = dateArr.join(' ').slice(0, -1)
    } else {
      formattedDate = getFormattedDate(node.date)
    }
  }

  return (
    <div className='postline'>
      <Link
        to={prefix ? `/${prefix}${node.slug}` : node.slug}
        key={node.id}
        className="post"
      >
        <h3>{node.title}</h3>
        {node.template === "publication" && node.tags.includes("Preprint") ? ( // writes either Blog, Publication or Preprint
          <div className='post-index-tag'>
            <span>Preprint</span>
          </div>
        ) : node.template === "post" ? (
          <div className='post-index-tag'>
            <span>Blog post</span>
          </div>
        ) : node.template === "publication" ? (
          <div className='post-index-tag'>
            <span>Publication</span>
          </div>
          ) : null}
      </Link>
      <div className='post-time'>
        <time>{formattedDate}</time>
      </div>
    </div>
  )
}
